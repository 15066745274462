import * as React from "react"
import { Link } from "gatsby"
import { Row, Col, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import usePagination from "./common/pagination"

const ArticlesComponent = ({ data }) => {
  const PER_PAGE = 12
  const pageCount = Math.ceil(data.length / PER_PAGE)
  const Paginate = usePagination(data, PER_PAGE)

  return (
    <React.Fragment>
      {Paginate.currentData().map(article => {
        return (
          <Row className="my-4 align-items-center" key={article.slug}>
            {article.image && (
              <Col md={3} className="h-100">
                <Link to={`/blog/artigo/${article.slug}`}>
                  <GatsbyImage
                    image={
                      article.image.imageFile.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </Link>
              </Col>
            )}
            <Col className="h-100">
              <div className="mt-2 mb-1">
                <h4 className="gradient-text">{article.title}</h4>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: article.introduction }}
              ></div>
              <Link
                className="btn btn-primary btn-sm"
                to={`/blog/artigo/${article.slug}`}
              >
                Leia mais
              </Link>
            </Col>
          </Row>
        )
      })}
      {data.length > PER_PAGE && (
        <Row className="mt-4">
          <Col className="text-left">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.prev()
              }}
              disabled={Paginate.currentPage === 1 ? true : false}
            >
              Anterior
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.next()
              }}
              disabled={Paginate.currentPage === pageCount ? true : false}
            >
              Próxima
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default ArticlesComponent
